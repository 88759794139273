import React from "react";
import theme from "theme";
import { Theme, Text, Image, Icon, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { GiChickenOven, GiCandyCanes, GiBerriesBowl } from "react-icons/gi";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"menu"} />
		<Helmet>
			<title>
				Меню | Visit Ryold - Пивна спортсмена
			</title>
			<meta name={"description"} content={"Пориньте в меню, створене для тих, хто прагне гри та жадає пригод. У Visit Ryold кожна страва та напій розповідає історію пристрасті, смаку та родзинки."} />
			<meta property={"og:title"} content={"Меню | Visit Ryold - Пивна спортсмена"} />
			<meta property={"og:description"} content={"Пориньте в меню, створене для тих, хто прагне гри та жадає пригод. У Visit Ryold кожна страва та напій розповідає історію пристрасті, смаку та родзинки."} />
			<meta property={"og:image"} content={"https://visitryold.com/images/bg.jpg"} />
			<link rel={"shortcut icon"} href={"https://visitryold.com/images/icon.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://visitryold.com/images/icon.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://visitryold.com/images/icon.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://visitryold.com/images/icon.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://visitryold.com/images/icon.png"} />
			<meta name={"msapplication-TileImage"} content={"https://visitryold.com/images/icon.png"} />
			<meta name={"msapplication-TileColor"} content={"https://visitryold.com/images/icon.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="90px 0 100px 0" background="--color-darkL2" quarkly-title="Price-18">
			<Override slot="SectionContent" />
			<Text margin="0px 0px 20px 0px" text-align="center" font="normal 500 46px/1.2 --fontFamily-sans" color="--light">
				Родзинки бару
			</Text>
			<Text
				margin="0px 0px 70px 0px"
				text-align="center"
				font="normal 300 25px/1.5 --fontFamily-serifGaramond"
				color="--light"
				width="900px"
				align-self="center"
				md-align-self="auto"
				max-width="100%"
			>
				У Visit Ryold кожна страва та напій розповідає історію пристрасті та смаку.
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="0 34px"
				lg-grid-template-columns="1fr"
				lg-grid-gap="35px 0"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
					background="--color-light"
					border-radius="25px"
					justify-content="space-between"
				>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="column"
						align-items="center"
					>
						<Image
							src="https://visitryold.com/images/2.jpg"
							display="block"
							width="100%"
							height="300px"
							object-fit="cover"
							border-radius="25px 25px 0 0"
						/>
						<Icon
							category="gi"
							icon={GiChickenOven}
							size="64px"
							background="#ffec00"
							padding="15px 15px 15px 15px"
							border-radius="50%"
							margin="-40px 0px 20px 0px"
						/>
						<Box
							min-width="100px"
							min-height="100px"
							margin="0px 0px 25px 0px"
							display="flex"
							flex-direction="column"
							align-items="center"
							padding="0px 25px 0px 25px"
						>
							<Text margin="0px 0px 10px 0px" color="--darkL1" text-align="center" font="normal 400 20px/1.5 --fontFamily-sansHelvetica">
								120 грн
							</Text>
							<Text margin="0px 0px 10px 0px" color="--darkL1" font="normal 600 38px/1.2 --fontFamily-sans">
								Тачдаун Такос
							</Text>
							<Text margin="0px 0px 30px 0px" color="--darkL1" font="normal 300 16px/1.5 --fontFamily-sansTrebuchet" text-align="center">
								Апетитне тріо начинок, загорнуте в м'яку оболонку, з нашим фірмовим соусом. Ідеально підходить для того, щоб поділитися... або ні!
							</Text>
						</Box>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
					background="--color-light"
					border-radius="25px"
					justify-content="space-between"
				>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="column"
						align-items="center"
					>
						<Image
							src="https://visitryold.com/images/3.jpg"
							display="block"
							width="100%"
							height="300px"
							object-fit="cover"
							border-radius="25px 25px 0 0"
						/>
						<Icon
							category="gi"
							icon={GiCandyCanes}
							size="64px"
							background="#ffec00"
							padding="15px 15px 15px 15px"
							border-radius="50%"
							margin="-40px 0px 20px 0px"
						/>
						<Box
							min-width="100px"
							min-height="100px"
							margin="0px 0px 25px 0px"
							display="flex"
							flex-direction="column"
							align-items="center"
							padding="0px 25px 0px 25px"
						>
							<Text margin="0px 0px 10px 0px" color="--darkL1" text-align="center" font="normal 400 20px/1.5 --fontFamily-sansHelvetica">
								250 грн
							</Text>
							<Text margin="0px 0px 10px 0px" color="--darkL1" font="normal 600 38px/1.2 --fontFamily-sans">
								Slam Dunk Sliders
							</Text>
							<Text margin="0px 0px 30px 0px" color="--darkL1" font="normal 300 16px/1.5 --fontFamily-sansTrebuchet" text-align="center">
								Міні-бургери, що вражають уяву. Подаються з хрусткою картоплею фрі та нашим фірмовим соусом.
Хет-трик Хот-Вінгс - крильця, що просто палають! Обирайте свій рівень прожарювання та занурюйтесь у цю улюблену страву фанатів.
							</Text>
						</Box>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
					background="--color-light"
					border-radius="25px"
					justify-content="space-between"
				>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="column"
						align-items="center"
					>
						<Image
							src="https://visitryold.com/images/4.jpg"
							display="block"
							width="100%"
							height="300px"
							object-fit="cover"
							border-radius="25px 25px 0 0"
						/>
						<Icon
							category="gi"
							icon={GiBerriesBowl}
							size="64px"
							background="#ffec00"
							padding="15px 15px 15px 15px"
							border-radius="50%"
							margin="-40px 0px 20px 0px"
						/>
						<Box
							min-width="100px"
							min-height="100px"
							margin="0px 0px 25px 0px"
							display="flex"
							flex-direction="column"
							align-items="center"
							padding="0px 25px 0px 25px"
						>
							<Text margin="0px 0px 10px 0px" color="--darkL1" text-align="center" font="normal 400 20px/1.5 --fontFamily-sansHelvetica">
								180 грн
							</Text>
							<Text margin="0px 0px 10px 0px" color="--darkL1" font="normal 600 36px/1.2 --fontFamily-sans">
								Марафонська піца "Маргарита"
							</Text>
							<Text margin="0px 0px 30px 0px" color="--darkL1" font="normal 300 16px/1.5 --fontFamily-sansTrebuchet" text-align="center">
								Піца на тонкому тісті зі свіжими інгредієнтами, полита оливковою олією з додаванням базиліку. Шматочок досконалості!
							</Text>
						</Box>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section
			padding="90px 0 100px 0"
			display="flex"
			flex-direction="column"
			background="--color-darkL2"
			quarkly-title="Cards-2"
		>
			<Override slot="SectionContent" />
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				lg-flex-direction="column"
				sm-width="100%"
			>
				<Box
					min-width="100px"
					min-height="100px"
					margin="0px 0px 0 0"
					display="flex"
					flex-direction="column"
					justify-content="flex-end"
					padding="30px 30px 30px 30px"
					background="rgba(0, 0, 0, 0) url(https://visitryold.com/images/5.jpg) center/cover repeat scroll padding-box"
					height="500px"
					width="65%"
					lg-width="auto"
					lg-margin="0px 0px 30px 0"
					lg-height="400px"
				/>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					grid-template-columns="repeat(3, 1fr)"
					grid-gap="0 50px"
					lg-grid-gap="35px 0"
					md-grid-template-columns="1fr"
					lg-grid-template-columns="1fr"
					flex-direction="column"
					margin="0px 0px 0px 15px"
					width="35%"
					lg-width="auto"
					lg-margin="0px 0px 0px 0"
				>
					<Box
						min-width="100px"
						min-height="100px"
						md-margin="0px 0px 0 0px"
						margin="0px 0px 0 0"
						display="flex"
						flex-direction="column"
						justify-content="flex-end"
						padding="30px 30px 30px 30px"
						background="rgba(0, 0, 0, 0) url(https://visitryold.com/images/1.jpg) center/cover repeat scroll padding-box"
						height="250px"
						lg-height="300px"
					/>
					<Box
						min-width="100px"
						min-height="100px"
						md-margin="0px 0px 0 0px"
						margin="0px 0px 0 0"
						display="flex"
						flex-direction="column"
						justify-content="flex-end"
						padding="30px 30px 30px 30px"
						background="rgba(0, 0, 0, 0) url(https://visitryold.com/images/11.jpg) center/cover repeat scroll padding-box"
						height="250px"
						lg-height="300px"
					/>
				</Box>
			</Box>
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				lg-flex-direction="column"
				sm-width="100%"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					grid-template-columns="repeat(3, 1fr)"
					grid-gap="0 50px"
					lg-grid-gap="35px 0"
					md-grid-template-columns="1fr"
					lg-grid-template-columns="1fr"
					flex-direction="column"
					margin="0px 0px 0px 15px"
					width="35%"
					lg-width="auto"
					lg-margin="0px 0px 0px 0"
				>
					<Box
						min-width="100px"
						min-height="100px"
						md-margin="0px 0px 0 0px"
						margin="0px 0px 0 0"
						display="flex"
						flex-direction="column"
						justify-content="flex-end"
						padding="30px 30px 30px 30px"
						background="rgba(0, 0, 0, 0) url(https://visitryold.com/images/9.jpg) center/cover repeat scroll padding-box"
						height="250px"
						lg-height="300px"
					/>
					<Box
						min-width="100px"
						min-height="100px"
						md-margin="0px 0px 0 0px"
						margin="0px 0px 0 0"
						display="flex"
						flex-direction="column"
						justify-content="flex-end"
						padding="30px 30px 30px 30px"
						background="rgba(0, 0, 0, 0) url(https://visitryold.com/images/10.jpg) center/cover repeat scroll padding-box"
						height="250px"
						lg-height="300px"
					/>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					margin="0px 0px 0 0"
					display="flex"
					flex-direction="column"
					justify-content="flex-end"
					padding="30px 30px 30px 30px"
					background="rgba(0, 0, 0, 0) url(https://visitryold.com/images/7.jpg) center/cover repeat scroll padding-box"
					height="500px"
					width="65%"
					lg-width="auto"
					lg-margin="0px 0px 30px 0"
					lg-height="400px"
				/>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});